<template>
  <div class="list-view product-checkout mt-0">
    <!-- Products List -->
    <e-commerce-checkout-step-cart-products />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>
        <div class="price-details">
          <h6 class="price-title">Price Details</h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">Total Price</div>
              <div
                class="detail-amt"
                v-if="$store.state['app-ecommerce'].cartItems.length > 0"
              >
                ${{ totalAmount }}.00
              </div>
              <div v-else class="detail-amt">$0.00</div>
            </li>
            <!-- <li class="price-detail">
              <div class="detail-title">EMI Eligibility</div>
              <a href="javascript:void(0)" class="detail-amt text-primary"
                >Details</a
              >
            </li> -->
          </ul>
          <hr />
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">Total</div>
              <div
                class="detail-amt font-weight-bolder"
                v-if="$store.state['app-ecommerce'].cartItems.length > 0"
              >
                ${{ totalAmount }}.00
              </div>
              <div v-else class="detail-amt font-weight-bolder">$0.00</div>
            </li>
          </ul>
          <b-button variant="primary" block @click="$emit('next-step')">
            Continue
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import ECommerceCheckoutStepCartProducts from "./ECommerceCheckoutStepCartProducts.vue";

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  data() {
    return {
      coupons: "",
    };
  },
  computed: {
    totalAmount() {
      let total = 0;
      this.$store.state["app-ecommerce"].cartItems.forEach((i) => {
        total += parseInt(i.price);
      });
      return total;
    },
  },
  methods: {
    //
  },
};
</script>

<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Account Payable Contact</b-card-title>
          <b-card-text class="text-muted mt-25">
            Be sure to check "Deliver to this address" when you have finished
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Full Name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group label="Title" label-for="title" class="mb-2">
                  <b-form-input
                    id="title"
                    placeholder="John Doe"
                    v-model="userData.title"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Mobile Number -->
            <b-col cols="12" md="6" class="mb-2">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group label="Full Name" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="userData.fullName"
                    type="text"
                    placeholder="Name"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Flat House No -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Address"
                rules="required"
              >
                <b-form-group label="Address" label-for="address" class="mb-2">
                  <b-form-input
                    id="address"
                    placeholder="9447 Glen Eagles Drive"
                    v-model="userData.billing__address"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Email -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Email Address"
                rules="required|email"
              >
                <b-form-group
                  label="Email Address"
                  label-for="Email Address"
                  class="mb-2"
                >
                  <b-form-input
                    id="email_address"
                    placeholder="john@email.com"
                    v-model="userData.billing__email"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- City -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Town/city"
                rules="required"
              >
                <b-form-group label="Town/City" label-for="city" class="mb-2">
                  <b-form-input
                    id="city"
                    placeholder="Lewis Center"
                    v-model="userData.billing__city"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- pincode -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Postal"
                rules="required|integer"
              >
                <b-form-group
                  label="Postal Code"
                  label-for="postal"
                  class="mb-2"
                >
                  <b-form-input
                    id="postal"
                    v-model="userData.billing__code"
                    type="number"
                    placeholder="201301"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- state -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="State"
                rules="required"
              >
                <b-form-group label="State" label-for="state" class="mb-2">
                  <b-form-input
                    id="state"
                    placeholder="California"
                    v-model="userData.billing__state"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Address Type -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Phone Number"
                rules="required"
              >
                <b-form-group
                  label="Phone Number"
                  label-for="phone_number"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="phone_number"
                    placeholder="555-555-555"
                    v-model="userData.billing__phone__number"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Address Type -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Company"
                rules="required"
              >
                <b-form-group
                  label="Company"
                  label-for="company"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="company"
                    placeholder="Company Name"
                    v-model="userData.billing__company"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Submit Button -->
            <b-col cols="12">
              <b-button @click="updateUserRecord(userData)" variant="primary">
                Save Address
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Right Col -->
      <div class="customer-card">
        <b-card title="Account Payable Contact">
          <b-card-text class="mb-0"></b-card-text>
          <b-card-text>{{ userData.billing__company }}</b-card-text>
          <b-card-text>{{ userData.title }}</b-card-text>
          <b-card-text>{{ userData.fullName }}</b-card-text>
          <b-card-text
            >{{ userData.billing__address }}
            {{ userData.billing__code }}</b-card-text
          >
          <b-card-text
            >{{ userData.billing__city }} {{ userData.billing__state }}
          </b-card-text>
          <b-card-text>{{ userData.billing__email }}</b-card-text>
          <b-card-text>{{ userData.billing__phone__number }}</b-card-text>
          <div v-if="userData.billing__address">
            <b-button variant="primary" block @click="$emit('next-step')">
              Continue
            </b-button>
          </div>
        </b-card>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required, integer, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { useUser } from "../../user/useUser";

export default {
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      () => {}
    );

    const { updateUserRecord } = useUser();

    const onSubmit = () => {
      //emit("next-step");
    };

    return {
      // Form
      onSubmit,

      updateUserRecord,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // FOrm Validators
      required,
      integer,
      email,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

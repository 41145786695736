<template>
  <div class="list-view product-checkout">
    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Payment Options</b-card-title>
        <b-card-text class="text-muted mt-25">
          Be sure to click on correct payment option
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <!-- Radios -->
        <b-form-group>
          <div v-if="selected == 'paypal'" style="width: 300px">
            <div id="paypal-button-container"></div>
          </div>

          <div
            v-if="selected && selected == 'market_place_offer'"
            class="
              d-flex
              flex-wrap
              align-items-center
              justify-content-start
              my-1
            "
          >
            <validation-observer ref="marketPlaceForm" #default="{ invalid }">
              <b-form
                @submit.prevent="saveMarketPlaceID"
                class="d-flex align-items-center justify-content-center"
              >
                <label for="aws-account" class="text-nowrap mr-1">
                  Enter AWS Account:
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="AWS Account"
                  rules="required|length:12"
                >
                  <b-form-input
                    id="market-place-id"
                    v-model="marketPlaceId"
                    :state="errors.length > 0 ? false : null"
                    name="market-place-id"
                    placeholder="AWS Account"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <b-button
                  :disabled="invalid"
                  type="submit"
                  variant="primary"
                  class="ml-1"
                  >Save</b-button
                >
              </b-form>
            </validation-observer>
          </div>
          <hr
            v-if="selected && selected == 'market_place_offer'"
            class="mb-2 mt-1"
          />

          <!--
            <b-form-radio
            @change="handlePaymentSelected"
            v-model="selected"
            name="payment-method"
            value="paypal"
          >
            Paypal
          </b-form-radio>
          -->

          <b-form-radio
            @change="handlePaymentSelected"
            v-model="selected"
            name="payment-method"
            class="mt-1"
            value="invoice"
          >
            Invoice
          </b-form-radio>
          <b-form-radio
            @change="handlePaymentSelected"
            name="payment-method"
            class="mt-1"
            v-model="selected"
            value="market_place_offer"
          >
            Market Place Offer
          </b-form-radio>
        </b-form-group>

        <!--
          <hr class="my-2" />

        <p class="cursor-pointer">
          <feather-icon icon="PlusCircleIcon" size="21" class="mr-75" />
          <span class="align-middle">Add Gift Card</span>
        </p>
        -->
      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Price Details">
        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">Amount Payable</div>
            <div class="detail-amt font-weight-bolder">
              <div class="detail-title">Total Price</div>
              <div
                class="detail-amt"
                v-if="$store.state['app-ecommerce'].cartItems.length > 0"
              >
                ${{ totalAmount }}
              </div>
              <div v-else class="detail-amt">$0.00</div>
            </div>
          </li>
        </ul>
        <b-button
          :disabled="disabledButton"
          variant="primary"
          block
          @click="handleOrderComplete"
        >
          <div v-if="isLoading" class="loader">Processing order...</div>
          <div v-else>Complete Order</div>
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BForm,
} from "bootstrap-vue";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, length } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  createProduct,
  uuid,
  createMarketPlaceRecord,
  getMarketPlaceRecord,
  publishMessage,
} from "@/aws/Api";
export default {
  components: {
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      isLoading: false,
      marketPlaceId: "",
      status: false,
      //
      required,
      length,
    };
  },
  computed: {
    disabledButton: function () {
      switch (this.selected) {
        case "invoice":
          return false;
        case "market_place_offer":
          if (this.status) {
            return false;
          } else {
            return true;
          }
        default:
          return true;
      }
    },
    userInfo: function () {
      return store.state["app-users"].userInfo;
    },
    totalAmount() {
      let total = 0;
      this.$store.state["app-ecommerce"].cartItems.forEach((i) => {
        total += parseInt(i.price);
      });
      return total;
    },
  },
  methods: {
    // save market place id
    async saveMarketPlaceID() {
      try {
        await createMarketPlaceRecord({
          email: this.userInfo.email,
          id: this.userInfo.user__id,
          marketPlaceId: this.marketPlaceId,
        });
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum MarketPlace`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `Thank you for providing us with your Market Place ID. We are going to extend an offer to you!.`,
          },
        });
        this.status = true;
      } catch (error) {
        this.isLoading = false;
        this.status = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum MarketPlace`,
            icon: "CoffeeIcon",
            variant: "error",
            text: `An error occured while saving this account id ${error}`,
          },
        });
      }
    },
    // check saved user market place id
    async checkUserMarketPlaceID() {
      try {
        if (!this.userInfo) return;
        return await getMarketPlaceRecord(
          this.userInfo.user__id,
          this.userInfo.email
        );
      } catch (error) {
        console.log(error);
      }
    },
    // handle selected payment
    async handlePaymentSelected() {
      switch (this.selected) {
        case "paypal":
          this.initPayPalButton();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Automatum Payment`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have selected PayPal as your mode of payment. Please go ahead and make your payment and we will process your template!`,
            },
          });
          break;
        case "market_place_offer":
          let response;
          if (!this.marketPlaceId) {
            response = await this.checkUserMarketPlaceID();
          }
          if (response) {
            this.marketPlaceId = response.marketPlaceId;
            this.status = true;
          }
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Automatum Payment`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have selected Market Place as your mode of payment. Please go ahead and make your payment and we will process your template!`,
            },
          });
          break;
        case "invoice":
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Automatum Payment`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have selected Invoice as your mode of payment. We have emailed an invoice to you and once payment is confirmed we will process your template!`,
            },
          });
          break;
        default:
          break;
      }
    },
    // load paypal
    initPayPalButton() {
      paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "paypal",
          },

          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [{ amount: { currency_code: "USD", value: 1 } }],
            });
          },

          onApprove: function (data, actions) {
            return actions.order.capture().then(function (orderData) {
              // Full available details
              console.log(
                "Capture result",
                orderData,
                JSON.stringify(orderData, null, 2)
              );

              // Show a success message within this page, e.g.
              const element = document.getElementById(
                "paypal-button-container"
              );
              element.innerHTML = "";
              element.innerHTML = "<h3>Thank you for your payment!</h3>";

              // Or go to another URL:  actions.redirect('thank_you.html');
            });
          },

          onError: function (err) {
            console.log(err);
          },
        })
        .render("#paypal-button-container");
    },
    // handle order complete
    async handleOrderComplete() {
      try {
        if (store.state["app-ecommerce"].cartItems.length == 0) return;
        this.isLoading = true;
        const purchasedProducts = store.state["app-ecommerce"].cartItems;
        await this.handlePurchasedProducts(
          this.sortedProducts(purchasedProducts)
        );
        // clear vuex store for the transaction
        store.dispatch("app-ecommerce/UPDATE_CART", []);
        store.dispatch("app-ecommerce/SELECT_ITEM", {});
        store.commit("app-ecommerce/UPDATE_CART_ITEMS_COUNT", 0);
        // send user to product section
        this.isLoading = false;
        this.$router.replace("/apps/products").then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Automatum Payment`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `We have saved your product and once your payment will be confirmed you will be able to download your templates.`,
            },
          });
        });
      } catch (error) {
        this.isLoading = false;
      }
    },
    // handle purchased product
    async handlePurchasedProducts(sorted) {
      try {
        // generate each id for each product in the cart
        const response = sorted.map(async (product) => {
          await createProduct(product);
        });
        // resolve all orders
        await Promise.all(response);
        // publish order to be processed
        await publishMessage({
          type: this.selected,
          id: this.userInfo.user__id,
          username: this.userInfo.username,
          email: this.userInfo.email,
          marketPlaceId: this.marketPlaceId,
        });
        this.$router.push("/apps/products");
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum Payment`,
            icon: "CoffeeIcon",
            variant: "error",
            text: `An error occurred while processing your order.`,
          },
        });
      }
    },
    // sort purchased products and generate uuid
    sortedProducts(purchasedProducts) {
      let sorted = [];
      for (let index = 0; index < purchasedProducts.length; index++) {
        const product = purchasedProducts[index];
        product.business_id = this.userInfo.user__id;
        product.product_id = `${uuid()}+${index}`;
        product.payment_confirmed = false;
        sorted.push(product);
      }
      return sorted;
    },
  },
};
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}

.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>

import store from '@/store'

export const useUser = () => {

  const updateUserRecord = async (user) => {
    if (user) {
      await store.dispatch("app-users/EDIT_USER_INFO", user);
    }
  }

  return {
    updateUserRecord,
  }
}